import { doc, setDoc } from "firebase/firestore";
import EnvStore from "../../store/secretStore";
import UserAuthStore from "../../store/userStore";

export const updateRecaching = async (uid, value) => {
    const { user, orgId } = UserAuthStore.getState();

    try {
        const db = EnvStore.getState().db;
        const chatDocRef = doc(
            db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            uid
        );
        // Use setDoc to update the document
        await setDoc(
            chatDocRef,
            { recaching: value },
            { merge: true }
        );

    } catch (error) {
        console.error("Error updating chat title in Firestore:", error);
    }
};
