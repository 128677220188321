import { Navigate, Outlet } from "react-router-dom";
import UserAuthStore from "../store/userStore";

const ProtectedRoute = () => {
  const { user } = UserAuthStore.getState(); // Get the authenticated user from Zustand (or your auth state)

  return user ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
