import { useEffect } from "react";
import ChatMessages from "./Messages/messages";
import Header from "./Contents/Header";
import { useNavigate, useParams } from "react-router-dom";
import addChat from "../../utils/firestoreFunctions/addChat";
import { getChat } from "../../utils/firestoreFunctions/getChat";
import UserAuthStore from "../../store/userStore";
import { loadFirstChatWithMessageCount } from "../../utils/firestoreFunctions/loadChatMessages";
import ChatStore from "../../store/chatStore";
import "../../assets/css/styles.css";

const MainContent = () => {
  const chat_id = useParams().id;
  const { user } = UserAuthStore();

  const RemoveUpload = () => {};
  const navigate = useNavigate();
  const { setActiveChat } = ChatStore();

  useEffect(() => {
    const shouldCreateNewChat = async () => {
      if (chat_id === undefined && user?.uid) {
        const lastChat = await loadFirstChatWithMessageCount();
        if (lastChat === null || lastChat?.messageCount) {
          const ch_uid = await addChat({ RemoveUpload, getChat });
          setActiveChat(ch_uid);
          navigate(`/chat/${ch_uid}`);
        } else {
          setActiveChat(lastChat.chatId);
          navigate(`/chat/${lastChat.chatId}`);
        }
      }
    };
    shouldCreateNewChat();
  }, [user, chat_id]);

  return (
    <div className={`flex flex-col flex-grow min-w-0 relative hide-scrollbar`}>
      <Header />
      {chat_id ? <ChatMessages chat_id={chat_id} /> : <></>}
    </div>
  );
};

export default MainContent;
