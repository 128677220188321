import { doc, deleteDoc } from "firebase/firestore";
import ChatStore from "../../store/chatStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";


export const deleteChat = async (uid) => {
  const { activeChat, deleteChatTitle } = ChatStore.getState();
  const { user, orgId } = UserAuthStore.getState();

  try {
    if (activeChat !== uid) {
      deleteChatTitle(uid)

      await deleteDoc(
        doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid, "chats", uid)
      );
    }
  } catch (error) {
    console.error("Error deleting chat:", error);
  }
};
