import React, { useState, useEffect } from "react";
import MessageAlert from "../components/Authentication/MessageAlert";
import OAuthButton from "../components/Authentication/OAuthButton";
import Footer from "../components/Authentication/Footer";
import { Logo, LogoLight } from "../assets";
import {
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { isUserInFirestore } from "../utils/firebaseUtils";
import { fetchAllOrganizationDomains } from "../utils/firestoreFunctions/fetchOrgDomains";
import { updateUserDocumentId } from "../utils/firestoreFunctions/updateUserId";
import UserAuthStore from "../store/userStore";
import EnvStore from "../store/secretStore";
import { useTheme } from "../context/ThemeProvider";
import Loader from "../components/Loader/Loader";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setUser, setOrgId } = UserAuthStore.getState();
  const { theme } = useTheme();

  const handleLogin = async () => {
  //   const userAgent = navigator.userAgent;
  // alert(userAgent);
    setLoading(true);
    console.log("login clicked")
    try {
      const domainMap = await fetchAllOrganizationDomains();
      console.log("login clicked domain" , domainMap)

      const domains = Object.keys(domainMap);
      console.log("login clicked domains" , domains)

      const provider = EnvStore.getState().googleProvider;
      const auth = EnvStore.getState().auth;
  
      // if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      //   // Use redirect method for Safari
      //   // alert("test login");
      //   const userCred = await signInWithRedirect(auth, provider);
      //   console.log(userCred)
      //   // navigate("/chat");
      //   return;
      // }
  
      const result = await signInWithPopup(auth, provider);
      // console.log(result)

      //google drive integration
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // localStorage.setItem("googleAccessToken", token);
  
      const user = result.user;
      console.log("login clicked user" , user)

      const userDomain = user.email.split("@")[1];
      console.log("login clicked userDomain" , userDomain)

  
      if (!domains.includes(userDomain)) {
        setLoading(false);
        console.log("your domain is not authorised login button")
        toast.warning("Your Domain is not authorized");
        await signOut(auth);
      } else {
        console.log("your domain is valid")
        setOrgId(domainMap[userDomain]);
        const isUser = await isUserInFirestore(
          EnvStore.getState().db,
          domainMap[userDomain],
          user.uid
        );
        console.log("login clicked user ",isUser)

        if (!isUser) {
          console.log("login clicked user not present",isUser)

          const done = await updateUserDocumentId(user.email, user.uid, domainMap[userDomain],user);
          console.log("login clicked done",done)

          if(done === true){
            navigate("/chat");
          }else{
            setLoading(false);
            console.log("done" , done)
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error login " , error)
      toast.error("Error signing in please try again");
    }
  };
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      EnvStore.getState().auth,
      async (user) => {
        console.log(user, "User Value")
        if (user) {
          const domainMap = await fetchAllOrganizationDomains();
          const userDomain = user.email.split("@")[1];
          const domains = Object.keys(domainMap);
          
          console.log("all domains -> " , domainMap )
          console.log("userDomain -> " , userDomain )
          console.log("domain keys -> " , domains )


          if (!domains.includes(userDomain)) {
            console.log("Domain does not match");
            try {
              await signOut();
            } catch (error) {
              console.log("User already signed out");
            }
            setLoading(false);
          } else {
            console.log("Domain is valid");
            console.log(" domain test " , domainMap[userDomain])
            console.log("user in else -> " , user)
            setOrgId(domainMap[userDomain]);
            setUser(user);
            // to check if it is needed
            const isUser = await isUserInFirestore(
              EnvStore.getState().db,
              domainMap[userDomain],
              user.uid
            );
            console.log("User presence in firestore - " , isUser)
            if (isUser) {
              console.log("navigating to chat")
              navigate("/chat");
            } else {
              console.log("user not present in firestore")
              setLoading(false);
            }
          }
        } else {
          console.log("user not present " , user)
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [navigate]);

  return (
    <>
      {loading ? (
        <div
          className={`h-screen flex items-center justify-center dark:bg-[#22222D] bg-white ${
            theme === "light" ? "bg-[LightBg] bg-cover bg-center" : ""
          }`}
        >
          <Loader />
        </div>
      ) : (
        <div
          className={`h-screen relative dark:bg-[#22222D] dark:text-white bg-[#F9F7F5] text-black`}
        >
          <MessageAlert />
          <div className="flex justify-center items-center h-full">
            <div className="absolute top-32">
              {theme === "dark" ? <Logo /> : <LogoLight />}
            </div>
            <OAuthButton theme={theme} onSignIn={handleLogin} />
            <Footer theme={theme} />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
