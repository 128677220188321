import { getCacheAndFileData } from "../utils/firestoreFunctions/getCacheandFileData";
import { getRecachingValue } from "../utils/firestoreFunctions/getRecaching";

export async function generateApi({
  apiUrl,
  modeRef,
  prompt,
  chat_id,
  files,
  orgId,
  user,
  defaultModel,
  expanded,
  selectedStyle
}) {
  const dta = await getCacheAndFileData(chat_id);
  // console.log(dta);
  const recachingValue = await getRecachingValue(chat_id);

  const cacheId = dta?.cache_id ?? null;
  const fileData = dta?.file_data ?? "";

  const body = JSON.stringify({
    type: modeRef,
    prompt: prompt,
    chat_uid: chat_id,
    file_url: files?.map((file) => file.downloadURL),
    org_id: orgId,
    uid: user?.uid,
    regenerate: null,
    style: selectedStyle,
    prompt_id: null,
    model_id: defaultModel,
    recaching: recachingValue || false,
    google_search: expanded,
    cache_id: cacheId,
    file_data: fileData,
  });

  const response = await fetch(apiUrl + "/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  if (!response.body) {
    throw new Error("Readable stream not supported in response.");
  }

  return response.body.getReader();
}
