import React, { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from './routes/Routes';
import { useTheme } from './context/ThemeProvider';
import { initializeConfigFromAPI } from './api/Secret/Secret';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isLoad, setLoad] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = theme === 'dark' ? '/favicon.ico' : '/favicon_light.ico';
    }
  }, [theme]);

  useEffect(() => {
    initializeConfigFromAPI(() => {
      setLoad(false);
    });
  }, []);

  if (isLoad) return null;

  return (
    <>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={2000} // Closes after 3 sec
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        draggable
        theme={theme}
      />
    </>
  );
}

export default App;
