import React, { useEffect, useRef, useState } from "react";
import { Dislike, Dot, Like, User } from "../../../assets";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import RegenerateDropdown from "../../Dropdowns/RegenerateDropdown";
import ChatStore from "../../../store/chatStore";
import remarkGfm from "remark-gfm";
import IconPhotonDark from "../../../assets/images/Photon_icon_small.png";
import IconPhotonLight from "../../../assets/images/Photon_lighticon_small.png";
import CopyButton from "../../Button/CopyButton";
import { FileStore } from "../../../store/fileUploadStore";

const MarkdownRenderer = ({ content, onCopyCode }) => (
  <ReactMarkdown
    className="prose prose-invert max-w-none leading-5 font-sans text-xs sm:text-[15px] my-1"
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeHighlight]}
    components={{
      p: ({ children }) => (
        <p className="my-1.5 sm:my-3 leading-5">{children}</p>
      ),
      code: ({ inline, children, ...props }) =>
        inline ? (
          <code className="px-1 py-0.5 rounded text-xs font-mono" {...props}>
            {children}
          </code>
        ) : (
          <div className="bg-[#F0F4F9] dark:bg-[#23241f] flex-wrap rounded-md p-1.5 sm:p-4 my-1.5 overflow-x-auto">
            <code className="text-xs font-mono leading-5" {...props}>
              {children}
            </code>
            {/* <CopyButton text={children} /> */}
          </div>
        ),
      table: ({ children }) => (
        <div className="overflow-x-auto my-2">
          <table className="w-full border-collapse border border-gray-300 dark:border-gray-700">
            {children}
          </table>
        </div>
      ),
      th: ({ children }) => (
        <th className="border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 px-2 py-1 text-left font-semibold">
          {children}
        </th>
      ),
      td: ({ children }) => (
        <td className="border border-gray-300 dark:border-gray-700 px-2 py-1">
          {children}
        </td>
      ),
      ul: ({ children }) => (
        <ul className="list-disc ml-5 sm:ml-6 my-2 space-y-1">{children}</ul>
      ),
      ol: ({ children }) => (
        <ol className="list-decimal ml-5 sm:ml-6 my-2 space-y-1">{children}</ol>
      ),
      li: ({ children }) => <li className="leading-5">{children}</li>,
      blockquote: ({ children }) => (
        <blockquote className="border-l-4 border-gray-400 dark:border-gray-600 pl-3 italic text-gray-600 dark:text-gray-300 my-3">
          {children}
        </blockquote>
      ),
      hr: () => (
        <hr className="border-t border-gray-300 dark:border-gray-700 my-3" />
      ),
    }}
  >
    {content}
  </ReactMarkdown>
);

const Actions = ({
  regenFunc,
  chatId,
  handlePrevious,
  handleNext,
  disablePrevious,
  disableNext,
  currentIndex,
  totalAnswers,
  showLoadingAnswer,
  handleCopyContent,
  content,
  files,
}) => (
  <div className="flex justify-between items-center w-full gap-2 sm:gap-0 mb-1.5">
    <div className="gap-4 flex cursor-pointer">
      {/* <CopyButton text={content} /> */}
      {/* <Like />
      <Dislike />
      <Dot /> */}
    </div>

    <div className="flex justify-end gap-1.5 items-center w-full h-full sm:w-auto">
      {totalAnswers > 1 && (
        <div className="flex items-center gap-1.5 sm:gap-2">
          <button
            className={`text-gray-400 p-0.5 sm:p-0 ${
              disablePrevious
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handlePrevious}
            disabled={disablePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          <span className="text-gray-400 font-medium text-xs sm:text-base">
            {currentIndex + 1}/{totalAnswers}
          </span>

          <button
            className={`text-gray-400 p-0.5 sm:p-0 ${
              disableNext
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handleNext}
            disabled={disableNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      )}
      {totalAnswers > 0 && (
        <div>
          <RegenerateDropdown
            chatId={chatId}
            onRegenerate={regenFunc}
            showLoadingAnswer={showLoadingAnswer}
            files={files}
          />
        </div>
      )}
    </div>
  </div>
);

const OneMessage = ({ index, chat, user, isLatest, theme, regenFunc }) => {
  const messageRef = useRef(null);
  const { isLatestMessageLoading } = ChatStore();
  const [currentIndex, setCurrentIndex] = useState(chat?.currentIndex ?? 0);
  const currentAnswer = chat.answers[currentIndex];
  const [showLoadingAnswer, setShowLoadingAnswer] = useState(false); //is message loading
  const [answerStarted, setAnswerStarted] = useState(false);
  const { files } = FileStore();

  useEffect(() => {
    setCurrentIndex(chat?.currentIndex ?? 0);
  }, [chat?.currentIndex]);

  useEffect(() => {
    // Set answerStarted to true only when actual content starts arriving
    if (currentAnswer?.content && currentAnswer.content.trim() !== "") {
      setAnswerStarted(true);
    }
  }, [currentAnswer?.content]);

  useEffect(() => {
    const chatArea = document.getElementById("chat-messages");
    if (chatArea && messageRef.current) {
      const isAtBottom =
        chatArea.scrollHeight - chatArea.scrollTop === chatArea.clientHeight;
      if (isAtBottom) {
        messageRef.current.style.marginBottom = "8px";
      } else {
        messageRef.current.style.marginBottom = "0";
      }
    }
  }, [chat]);

  // useEffect(() => {
  //   if (messageRef.current) {
  //     messageRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [chat]);

  const handleRegenerate = async (
    chatId,
    modelSelected,
    globalSearchDropdown
  ) => {
    setShowLoadingAnswer(true);
    try {
      await regenFunc(chatId, modelSelected, globalSearchDropdown);
    } catch (error) {
      console.error("Regeneration failed:", error);
    } finally {
      setShowLoadingAnswer(false);
    }
  };

  const handleNext = () => {
    if (currentIndex < chat.answers.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleCopyContent = () => {
    navigator.clipboard.writeText(currentAnswer.content || "");
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className="mt-1.5 w-full" ref={messageRef} key={chat?.content?.length}>
      <div
        className={`rounded-full bg-gray-200 flex items-center justify-center border-b-2 border-gray-300 dark:border-gray-700 mt-2 ${
          theme === "dark"
            ? "border-[#707070] border-[0.5px]"
            : "border-[#CCCCCC] border-[0.5px]"
        }`}
        style={{ height: "32px", width: "32px" }}
      >
        <img
          src={user.photoURL ? user.photoURL : User}
          alt="User Icon"
          className="rounded-full"
          style={{ width: "35px", objectFit: "cover" }}
        />
      </div>

      <div className="message rounded-lg dark:bg-[#30303D] dark:text-[#E8EEFF] bg-white text-[#0D3148] px-2 sm:px-4 py-1 mt-2 w-full break-words overflow-x-auto">
        <MarkdownRenderer content={chat.content} />
      </div>

      <div
        className={`rounded-full dark:bg-[#30303D] bg-white flex items-center justify-center relative mt-3 ${
          theme === "dark"
            ? "border-[#707070] border-[0.5px]"
            : "border-[#CCCCCC] border-[0.5px]"
        }`}
        style={{ height: "32px", width: "32px" }}
      >
        {isLatest && (isLatestMessageLoading || showLoadingAnswer) ? (
          <div
            className="w-4 h-4 border-2 border-t-2 dark:border-t-[#61FCD9] border-t-[#FF5B29] rounded-full animate-spin"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <img
            src={theme === "dark" ? IconPhotonDark : IconPhotonLight}
            alt="Bot Icon"
            className="w-4 object-cover"
          />
        )}
      </div>

      {/* Bot message section - only shown when answer has started */}
      {answerStarted && (
        <>
          <div
            key={index}
            className="message rounded-lg dark:bg-[#30303D] dark:text-[#E8EEFF] bg-white text-[#0D3148] px-2 sm:px-4 py-1 my-2 w-full break-words overflow-x-auto"
          >
            <MarkdownRenderer
              content={currentAnswer?.content}
              onCopyCode={handleCopyCode}
            />
            <Actions
              regenFunc={handleRegenerate}
              chatId={chat.id}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              disablePrevious={currentIndex === 0}
              disableNext={currentIndex === chat.answers.length - 1}
              currentIndex={currentIndex}
              totalAnswers={chat.answers.length}
              showLoadingAnswer={showLoadingAnswer}
              handleCopyContent={handleCopyContent}
              content={currentAnswer?.content}
              files={files}
            />
          </div>
          <div className="border-b border-gray-300 dark:border-gray-700"></div>
        </>
      )}
    </div>
  );
};

export default OneMessage;
