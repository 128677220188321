import React, { useEffect, useRef, useState } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import { useTheme } from "../../context/ThemeProvider";

const StyleDropdown = ({ selectedStyle, setSelectedStyle }) => {
  const dropdownRef = useRef(null);
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredStyle, setHoveredStyle] = useState(null);

  const styles = [
    {
      name: "Standard",
      description: "Default responses from Photon",
    },
    {
      name: "Formal",
      description: "Professional and structured responses",
    },
    {
      name: "Concise",
      description: "Brief and focused responses",
    },
    {
      name: "Explanatory",
      description: "Detailed explanations with context",
    },
    {
      name: "Academic",
      description: "Scholarly and research-oriented style",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (styleName) => {
    setSelectedStyle(styleName);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Selected Style Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center gap-2 px-3 sm:px-4 h-8 rounded-full transition-colors border ${
          theme === "dark"
            ? "bg-[#333341] border-[#6D808D]"
            : "bg-[#FEEAE6] border-[#6D808D4D]"
        }`}
      >
        <span className="text-[13px]">{selectedStyle || "Response Style"}</span>
        <FaChevronDown
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className={`absolute bottom-full mb-1 rounded-md shadow-lg overflow-hidden z-50 
          dark:text-white text-[#0D3148] xxs:min-w-[230px] min-w-[258px] border-[0.5px] ${
            theme === "dark"
              ? "bg-[#333341] border-[#66666666]"
              : "bg-white border-[#66666666]"
          }`}
        >
          <div className="px-4 py-2 text-[13px] dark:text-[#9FABCA] text-[#6D808D] opacity-100">
            How should Photon write responses?
          </div>

          <div className="flex p-2">
            {/* Left side - Style options */}
            <div className="w-1/2 mr-2">
              {styles.map((style) => (
                <button
                  key={style.name}
                  onClick={() => handleSelect(style.name)}
                  onMouseEnter={() => setHoveredStyle(style.name)}
                  onMouseLeave={() => setHoveredStyle(null)}
                  className={`w-full flex items-center justify-between px-2 py-1 text-left rounded-lg
                    ${
                      (hoveredStyle || selectedStyle) === style.name
                        ? ""
                        : ""
                    }
                    hover:bg-[#FFE9E6] dark:hover:bg-[#3A4D6A] dark:text-white transition-colors`}
                >
                  <span className="text-sm">{style.name}</span>
                  {selectedStyle === style.name && (
                    <FaCheck className="w-3.5 h-3.5 dark:text-emerald-500 text-[#FF5B29]" />
                  )}
                </button>
              ))}
            </div>

            {/* Right side - Preview */}
            <div className="w-1/2 p-2 flex justify-center items-center dark:bg-[#262631] dark:opacity-100 bg-[#6D808D] bg-opacity-10">
              <p className=" text-xs dark:text-white text-[#0D3148] italic">
                {styles.find((s) => s.name === (hoveredStyle || selectedStyle))
                  ?.description || styles[0].description}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StyleDropdown;
