import { toast } from "react-toastify";
import { FirebaseUpload } from "./firestoreFunctions/firebaseUpload";
import { FilePath } from "./firestoreFunctions/firestorePath";
import { updateRecaching } from "./firestoreFunctions/updateRecaching";

export const handleFileUpload = (
    e,
    chat_id,
    fileInputRef,
    setFiles,
    // setPromptDisable,
    files,
    user,
    orgId
) => {
    // setPromptDisable(true);
    updateRecaching(chat_id, true);

    e?.preventDefault();
    // e?.stopPropagation();

    const file = fileInputRef?.current?.files?.[0];
    if (!file) return;

    const data = {
        uniqueFileName: `${Date.now()}_${file.name}`,
        emailBeforeAt: user.email.split("@")[0],
        orgId,
    };

    let file__path = FilePath(data);

    // Validate file extension
    const allowedExtensions = ["pdf", "csv", "docx", "txt", "xlsx", "doc"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Invalid file type! Allowed types: pdf, csv, docx, doc, txt, xlsx.");
        return;
    }

    // Validate file size
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    if (fileSizeMB > 7) {
        toast.error("File size is greater than 7MB!");
        return;
    }

    if (files.length >= 5) {
        toast.error("Cannot upload more than 5 files!");
        return;
    }

    // Calculate total size of all uploaded files including the new file
    let totalSizeMB = 0;
    files.forEach((file) => {
        totalSizeMB += parseFloat(file.size) / (1024 * 1024);
    });
    totalSizeMB += parseFloat(fileSizeMB);
    // console.log("File Size" ,totalSizeMB);
    if (totalSizeMB > 10) {
        toast.error("Total file size exceeds 10MB limit. Cannot upload more files.");
        return;
    }

    // Upload file
    FirebaseUpload(chat_id, fileInputRef, file__path);
    setFiles([...files, file]);
};
