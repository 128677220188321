let config = {};

export const initializeConfig = (secrets) => {
  config = {
    domain: secrets["DOMAIN"],
    // apiUrl: secrets["API_URL"],
    // clientId: secrets["CLIENT_ID"],
    // clientSecret: secrets["CLIENT_SECRET"],
    // tokenEndpoint: "https://oauth2.googleapis.com/token", //no need
    // redirectUri: secrets["REDIRECT_URI"], //no need
    // orgId: secrets["ORG_ID"], //no need
    // socketUrl: secrets["SOCKET_URL"], //no need
  };
};

export const getConfig = () => config;
export const secretKey = process.env.REACT_APP_SECRET_KEY
export const apiUrl = process.env.REACT_APP_API_URL
