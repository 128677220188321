import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { FileStore } from "../../store/fileUploadStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";
import { logout } from "../../api/logout";

export const FirebaseUpload = async (chat_id, fileInputRef, file__path) => {
  const { user, orgId } = UserAuthStore.getState();
  const { setFileLoading, setProgress, files, setFiles } = FileStore.getState();

  // Reset progress at the start
  setFileLoading(true);

  if (!user) {
    logout();
    return;
  }

  try {
    const file = fileInputRef.current.files[0];
    const nFile = { name: file.name, size: file.size, downloadURL: "", file__path: file__path, progress: 0 };
    setFiles([...files, nFile]);
    if (!file) {
      throw new Error("No file provided for upload.");
    }

    console.log(file__path)
    // Create storage reference
    const storageRef = ref(EnvStore.getState().storage, file__path);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      chunkSize: 256 * 1024
    });

    // Handle upload state changes
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const currentProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        nFile.progress = currentProgress;
        setFiles([...files, nFile]);
      },
      (error) => {
        // Handle specific error cases
        switch (error.code) {
          case 'storage/unauthorized':
            console.error("User doesn't have permission to access the object");
            break;
          case 'storage/canceled':
            console.error("User canceled the upload");
            break;
          case 'storage/unknown':
            console.error("Unknown error occurred, inspect error.serverResponse");
            break;
          default:
            console.error("File upload error:", error);
        }

        // Reset states on error
        setFileLoading(false);
        throw error;
      },
      async () => {
        try {

          // Get download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          nFile.downloadURL = downloadURL;
          setFiles([...files, nFile]);

          // Update Firestore
          const userDocRef = doc(
            EnvStore.getState().db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            chat_id
          );

          await updateDoc(userDocRef, {
            file_name: arrayUnion(file.name),
            file_size: arrayUnion(file.size),
            file_url: arrayUnion(downloadURL),
            file_path: arrayUnion(file__path),
          });

          setFileLoading(false);
        } catch (error) {
          console.error("Error in upload completion:", error);
          setFileLoading(false);
          throw error;
        }
      }
    );
  } catch (error) {
    console.error("Error initiating upload:", error);
    setFileLoading(false);
    setProgress(0);
    throw error;
  }
};