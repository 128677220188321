import axios from "axios";
import { apiUrl } from "../constants/const";

export const getModelList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/get_models`);
    return response.data; // Ensure response is returned
  } catch (err) {
    console.error("Error fetching models:", err);
    return { models: [] }; // Return an empty array on failure to prevent errors
  }
};
