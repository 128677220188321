import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "highlight.js/styles/monokai-sublime.css";
import UserAuthStore from "../../../store/userStore";
import PromptStore from "../../../store/promptStore";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import EnvStore from "../../../store/secretStore";
import { apiUrl } from "../../../constants/const";
import UIStore from "../../../store/uiStore";
import { WelcomeSection } from "../Contents/WelcomeSection";
import UploadSection from "../Contents/UploadSection";
import OneMessage from "./SingleMessage";
import "../../../assets/css/styles.css";
import { useTheme } from "../../../context/ThemeProvider";
import ChatStore from "../../../store/chatStore";
import getMessages from "../../../utils/firestoreFunctions/getMessages";
import Prompt from "../Contents/Prompt";
import addMessages from "../../../utils/firestoreFunctions/addMessages";
import { FileStore } from "../../../store/fileUploadStore";
import { invokeCloudRun } from "../../../api/invoke";
import { generateApi } from "../../../api/generate";
import regenerate from "../../../api/regenerate";
import { getDefaultModel } from "../../../api/getDefaultModel";

const ChatMessages = ({ chat_id }) => {
  const messageContainerRef = useRef(null);
  const { theme } = useTheme();
  const [messages, setMessages] = useState([]);
  const [isLoadingMessages, setLoadingMessages] = useState(false);
  const [isMessagesRendered, setMessagesRendered] = useState(false);
  const { user, orgId } = UserAuthStore();
  const { modeRef } = UIStore.getState();
  const { expanded, setExpanded } = UIStore();
  const { updateChatTitle, setAddChatDisabled, setIsLatestMessageLoading } =
    ChatStore();
  const { files } = FileStore();
  const [defaultModel, setDefaultModel] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null); // to add the response style

  const scrollToBottom = (smooth = true) => {
    const scrollRef = document.querySelector("#chat-messages");
    if (scrollRef) {
      if (smooth)
        scrollRef.scrollTo({ top: scrollRef.scrollHeight, behavior: "smooth" });
      else
        scrollRef.scrollTo({
          top: scrollRef.scrollHeight,
          behavior: "instant",
        });
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (user?.uid) {
        setLoadingMessages(true);
        const out = await getMessages(chat_id);
        setMessages(out);
        setLoadingMessages(false);
        scrollToBottom();
      }
    };
    invokeCloudRun();
    const getDefaultModelValue = async () => {
      const model = await getDefaultModel();
      setDefaultModel(model.model);
    };
    getDefaultModelValue();
    fetchMessages();

    return () => {
      setIsLatestMessageLoading(false);
    };
  }, [chat_id, user?.uid, orgId]);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollToBottom(false);
      setMessagesRendered(true);
    }, 10);
  }, [messages.length]);

  if (isLoadingMessages || !user?.uid) {
    return null;
  }
  if ((!isLoadingMessages && messages.length === 0) || !chat_id) {
    return (
      <>
        <div className="flex flex-col flex-grow text-center xm:mt-14 xs:mt-14 xxs:mt-14 px-6 max-w-5xl w-full mx-auto">
          <WelcomeSection />
          <UploadSection chat_id={chat_id} />
        </div>
        <Prompt
          chat_id={chat_id}
          expanded={expanded}
          setExpanded={setExpanded}
          setSelectedStyle={setSelectedStyle}
          selectedStyle={selectedStyle}
          send={async () => {
            const { prompt, setPrompt } = PromptStore.getState();
            setPrompt("");
            setIsLatestMessageLoading(true);
            setMessages((prev) => [
              ...prev,
              {
                role: "user",
                content: prompt,
                answers: [
                  {
                    content: "",
                    timestamp: {
                      seconds: 1736245799,
                      nanoseconds: 84000000,
                    },
                    source: "None",
                    active: true,
                    role: "model",
                  },
                ],
                currentIndex: 0,
                source: "None",
                id: null,
                expanded,
                selectedStyle,
              },
            ]);
            const chatDocRef = doc(
              EnvStore.getState().db,
              "organisation",
              orgId,
              "users",
              user?.uid,
              "chats",
              chat_id
            );
            setAddChatDisabled(false);
            try {
              if (messages.length === 0) {
                updateChatTitle(chat_id, prompt);
              }

              const reader = await generateApi({
                apiUrl,
                modeRef,
                prompt,
                chat_id,
                files,
                orgId,
                user,
                defaultModel,
                expanded,
                selectedStyle,
              });

              const decoder = new TextDecoder("utf-8");
              let answerCont = "";
              let first = true;

              for (let done = false; !done; ) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;

                if (value) {
                  const chunk = decoder.decode(value, { stream: true });
                  // console.log(chunk);

                  if (chunk === "</END>") {
                    const promptId = await addMessages(
                      orgId,
                      chat_id,
                      prompt,
                      answerCont,
                      user?.uid,
                      null,
                      "None",
                      files?.map((file) => file.downloadURL),
                      defaultModel
                    );

                    setMessages((prevMessages) => {
                      const updatedMessages = [...prevMessages];
                      const lastMessage =
                        updatedMessages[updatedMessages.length - 1];
                      lastMessage.id = promptId;
                      lastMessage.source = files?.map(
                        (file) => file.downloadURL
                      );
                      return updatedMessages;
                    });
                  } else if (chunk === "</RETRY>") {
                    setMessages((prevMessages) => {
                      // Avoid directly modifying the state array
                      const updatedMessages = [...prevMessages];
                      const lastMessage =
                        updatedMessages[updatedMessages.length - 1];

                      // Prevent duplicate chunks being appended
                      lastMessage.answers[
                        lastMessage?.currentIndex ?? 0
                      ].content = "...";
                      return updatedMessages;
                    });
                  } else {
                    //Append the chunks
                    answerCont += chunk;

                    setMessages((prevMessages) => {
                      // Avoid directly modifying the state array
                      const updatedMessages = [...prevMessages];
                      const lastMessage =
                        updatedMessages[updatedMessages.length - 1];

                      // Prevent duplicate chunks being appended
                      if (
                        !lastMessage?.answers[
                          lastMessage?.currentIndex ?? 0
                        ].content.includes(chunk)
                      ) {
                        lastMessage.answers[
                          lastMessage?.currentIndex ?? 0
                        ].content = answerCont;
                      }

                      return updatedMessages;
                    });
                    const container = document.querySelector("#chat-messages");
                    if (
                      container.scrollHeight -
                        container.scrollTop -
                        container.clientHeight <
                      50
                    ) {
                      scrollToBottom();
                    }
                    if (first) {
                      first = false;
                      setTimeout(() => {
                        scrollToBottom();
                      }, 100);
                    }
                  }
                }
              }
              setIsLatestMessageLoading(false);
            } catch (error) {
              console.error("Error fetching messages:", error);
            }

            await updateDoc(chatDocRef, { updated_at: serverTimestamp() });
          }}
        />
      </>
    );
  }
  return (
    <>
      <div
        id="chat-messages"
        ref={messageContainerRef}
        className={` side-chat-scroll font-sans flex-grow max-w-full ${
          isMessagesRendered ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="max-w-5xl px-6 mx-auto h-full">
        {messages?.map((chat, index) => (
          <OneMessage
            key={index}
            index={index}
            chat={chat}
            user={user}
            isLatest={index === messages.length - 1}
            theme={theme}
            regenFunc={(id, modelSelected, globalSearchDropdown) => {
              const m = modelSelected ? modelSelected : defaultModel;
              return regenerate({
                id,
                messages,
                setMessages,
                modeRef,
                chat_id,
                orgId,
                user,
                updateChatTitle,
                files,
                modelSelected: m,
                setIsLatestMessageLoading,
                globalSearchDropdown,
              });
            }}
          />
        ))}
        </div>
      </div>

      <Prompt
        chat_id={chat_id}
        expanded={expanded}
        setExpanded={setExpanded}
        setSelectedStyle={setSelectedStyle}
        selectedStyle={selectedStyle}
        send={async () => {
          const { prompt, setPrompt } = PromptStore.getState();
          setPrompt("");
          setIsLatestMessageLoading(true);
          setMessages((prev) => [
            ...prev,
            {
              role: "user",
              content: prompt,
              answers: [
                {
                  content: "",
                  timestamp: {
                    seconds: 1736245799,
                    nanoseconds: 84000000,
                  },
                  source: "None",
                  active: true,
                  role: "model",
                },
              ],
              currentIndex: 0,
              source: "None",
              id: null,
              expanded,
              selectedStyle,
            },
          ]);
          const chatDocRef = doc(
            EnvStore.getState().db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            chat_id
          );
          setAddChatDisabled(false);
          try {
            if (messages.length === 0) {
              updateChatTitle(chat_id, prompt);
            }

            const reader = await generateApi({
              apiUrl,
              modeRef,
              prompt,
              chat_id,
              files,
              orgId,
              user,
              defaultModel,
              expanded,
              selectedStyle,
            });

            const decoder = new TextDecoder("utf-8");
            let answerCont = "";
            let first = true;

            for (let done = false; !done; ) {
              const { value, done: readerDone } = await reader.read();
              done = readerDone;

              if (value) {
                const chunk = decoder.decode(value, { stream: true });
                // console.log(chunk);

                if (chunk === "</END>") {
                  const promptId = await addMessages(
                    orgId,
                    chat_id,
                    prompt,
                    answerCont,
                    user?.uid,
                    null,
                    "None",
                    files?.map((file) => file.downloadURL),
                    defaultModel
                  );

                  setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const lastMessage =
                      updatedMessages[updatedMessages.length - 1];
                    lastMessage.id = promptId;
                    lastMessage.source = files?.map((file) => file.downloadURL);
                    return updatedMessages;
                  });
                } else if (chunk === "</RETRY>") {
                  setMessages((prevMessages) => {
                    // Avoid directly modifying the state array
                    const updatedMessages = [...prevMessages];
                    const lastMessage =
                      updatedMessages[updatedMessages.length - 1];

                    // Prevent duplicate chunks being appended
                    lastMessage.answers[
                      lastMessage?.currentIndex ?? 0
                    ].content = "...";
                    return updatedMessages;
                  });
                } else {
                  //Append the chunks
                  answerCont += chunk;

                  setMessages((prevMessages) => {
                    // Avoid directly modifying the state array
                    const updatedMessages = [...prevMessages];
                    const lastMessage =
                      updatedMessages[updatedMessages.length - 1];

                    // Prevent duplicate chunks being appended
                    if (
                      !lastMessage?.answers[
                        lastMessage?.currentIndex ?? 0
                      ].content.includes(chunk)
                    ) {
                      lastMessage.answers[
                        lastMessage?.currentIndex ?? 0
                      ].content = answerCont;
                    }

                    return updatedMessages;
                  });
                  const container = document.querySelector("#chat-messages");
                  if (
                    container.scrollHeight -
                      container.scrollTop -
                      container.clientHeight <
                    50
                  ) {
                    scrollToBottom();
                  }
                  if (first) {
                    first = false;
                    setTimeout(() => {
                      scrollToBottom();
                    }, 100);
                  }
                }
              }
            }
            setIsLatestMessageLoading(false);
          } catch (error) {
            console.error("Error fetching messages:", error);
          }

          await updateDoc(chatDocRef, { updated_at: serverTimestamp() });
        }}
      />
    </>
  );
};

export default ChatMessages;
