import { doc, getDoc } from "firebase/firestore";
import EnvStore from "../../store/secretStore";
import UserAuthStore from "../../store/userStore";

export const getRecachingValue = async (uid) => {
    const { user, orgId } = UserAuthStore.getState();
    
    try {
        const db = EnvStore.getState().db;
        const chatDocRef = doc(
            db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            uid
        );

        const docSnap = await getDoc(chatDocRef);

        if (docSnap.exists()) {
            return docSnap.data().recaching; // Return the recaching value
        } else {
            console.warn("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching recaching value from Firestore:", error);
        return null;
    }
};
