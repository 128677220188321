import { useTheme } from "../../context/ThemeProvider";
import { WebIconDark, WebIconLight } from "../../assets";

export default function GlobalSearch({ expanded, setExpanded, disabled }) {
  const { theme } = useTheme();

  return (
    <div className="relative">
      <button
        className={`flex items-center px-2 h-8 rounded-full transition-all duration-1000 ease-out border ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${expanded ? "w-[85px] gap-2 ease-out duration-500" : ""} ${
          theme === "dark"
            ? expanded
              ? "bg-[#37E8C51A] text-[#37E8C5] border-[#37E8C5]" // Dark mode expanded state
              : "bg-[#333341] border-[#6D808D] text-white" // Dark mode default
            : expanded
            ? "bg-[#FFE9E6] text-[#FF5B29] border-[#6D808D4D]" // Light mode expanded state
            : "bg-[#FFE9E6] text-[#FF5B29] border-[#E5E5E5]" // Light mode default
        }`}
        // onClick={() => setExpanded(!expanded)}
        onClick={() => !disabled && setExpanded(!expanded)}
      >
        {theme === "dark" ? (
          // Dark mode: icon color changes when expanded
          <WebIconDark
            className={`h-4 w-4 ${
              expanded ? "text-[#37E8C5] fill-[#37E8C5]" : "fill-white"
            }`}
          />
        ) : (
          // Light mode: icon color changes when expanded
          <WebIconLight
            className={`h-4 w-4 ${
              expanded ? "text-[#FF5B29] fill-[#FF5B29]" : "text-[#6D808D]"
            }`}
          />
        )}
        <span
          className={`overflow-hidden transition-transform ease-in-out text-[13px] ${
            expanded ? "opacity-100 w-auto" : "opacity-0 w-0"
          }`}
        >
          Search
        </span>
      </button>
    </div>
  );
}
