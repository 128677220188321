import { useTheme } from "../../../context/ThemeProvider";
import UserAuthStore from "../../../store/userStore";
import Dark_Icon from "../../../assets/images/Dark_Icon.png";
import LightLogoIcon from "../../../assets/images/LightLogoIcon.png";

export const WelcomeSection = () => {
  const { user } = UserAuthStore();
  const { theme } = useTheme();

  return (
    <>
      {/* Main container with better responsive spacing */}
      <div className="w-full px-4 sm:px-6">
        <div className="text-center mb-6 sm:mb-10 lg:mb-14 pt-6 sm:pt-8 lg:pt-12">
          {/* Mobile Logo - Better breakpoint control */}
          <div className="lg:hidden mb-6 flex justify-center">
            <img
              src={theme === "dark" ? Dark_Icon : LightLogoIcon}
              alt="Photon Logo"
              className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16"
            />
          </div>

          {/* Greeting - Progressive text scaling */}
          <h1 className="dark:text-textDarkGreen text-[#778898] text-xl sm:text-xl lg:text-[22px] mb-2 sm:mb-3 font-semibold">
            {user?.displayName ? `Hi, ${user?.displayName}` : "Hi,"}
          </h1>

          {/* Assistance text - Improved text hierarchy */}
          <h2 className="dark:text-white text-[#0D3148] text-lg lg:text-xl mb-3 font-bold whitespace-nowrap">
            Can I help you with anything?
          </h2>

          {/* Description - Better line break handling */}
          <p className="dark:text-textGray text-[#6D808D] text-[13px] xm:text-sm mx-auto mt-2">
            Photon is ready to assist you with anything you need, including
            <br className="hidden sm:block" />
            <span className="sm:hidden"> </span>
            queries answered and support for your regular work tasks.
          </p>
        </div>
      </div>
    </>
  );
};
