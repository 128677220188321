import React, { useState, useRef, useEffect } from "react";
import { IoArrowUp } from "react-icons/io5";
import { BlackMic, BlackMicActive, Mic, MicActive } from "../../../assets";
import { useTheme } from "../../../context/ThemeProvider";
import PromptStore from "../../../store/promptStore";
import { FileStore } from "../../../store/fileUploadStore";
import { DeleteUploadedFile } from "../../../utils/firestoreFunctions/deleteFileFromDb";
import UserAuthStore from "../../../store/userStore";
import { handleFileUpload } from "../../../utils/fileUploadHandler";
import StyleDropdown from "../../Dropdowns/StyleDropdown";
import { updateRecaching } from "../../../utils/firestoreFunctions/updateRecaching";
import AttachDropdown from "../../Dropdowns/AttachDropdown";
import GlobalSearch from "../../GlobalSearchIcon/iconGlobal";
import FileUploadItem from "../../FileUploadItem";
import "../../../assets/css/prompt.css";
import { toast } from "react-toastify";

const ChatInput = ({
  send,
  chat_id,
  expanded,
  setExpanded,
  selectedStyle,
  setSelectedStyle,
}) => {
  const { theme } = useTheme();
  const prompt = PromptStore((state) => state.prompt);
  const { setPrompt } = PromptStore.getState();
  const promptDisabled = PromptStore((state) => state.promptDisabled);
  const setPromptDisable = PromptStore((state) => state.setPromptDisable);

  const [text, setText] = useState("");
  const [micActive, setMicActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const recognitionRef = useRef(null);

  const { files } = FileStore();
  const setFiles = FileStore((state) => state.setFiles);
  const { user, orgId } = UserAuthStore.getState();
  const [uploadingFiles, setUploadingFiles] = useState({});

  // Function to handle file removal
  const removeFile = async (file) => {
    updateRecaching(chat_id, true);
    await DeleteUploadedFile(file?.file__path, chat_id);
    // Clean up upload state
    setUploadingFiles((prev) => {
      const newState = { ...prev };
      delete newState[file.name];
      return newState;
    });
  };

  // Initialize speech recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      let silenceTimeout;

      recognitionRef.current.onstart = () => {
        console.log("Speech recognition started");
        setIsListening(true);
        setMicActive(true);
      };

      recognitionRef.current.onresult = (event) => {
        console.log("Speech recognition result received");
        let finalTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          }
        }

        if (finalTranscript) {
          console.log("Final Transcript:", finalTranscript);
          setPrompt(finalTranscript);
        }

        // Reset silence timeout on every new result
        if (silenceTimeout) clearTimeout(silenceTimeout);
        silenceTimeout = setTimeout(() => {
          console.log("Silence detected for 3 seconds, stopping recognition.");
          recognitionRef.current?.stop();
        }, 2000);
      };

      recognitionRef.current.onend = () => {
        console.log("Speech recognition ended");
        setIsListening(false);
        setMicActive(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setIsListening(false);
        setMicActive(false);
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
      }
    };
  }, []);

  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop()); // Stop the stream after getting permission
      return true;
    } catch (error) {
      console.error("Error requesting microphone permission:", error);
      return false;
    }
  };

  const toggleMic = async () => {
    console.log("toggleMic called", { prompt, isListening }); // Add this

    if (prompt) {
      console.log("Prompt not empty, returning"); // Add this
      return;
    }

    if (!("webkitSpeechRecognition" in window)) {
      console.log("Speech recognition not supported"); // Add this
      toast.warning("Speech recognition is not supported in this browser.");
      return;
    }

    try {
      if (!isListening) {
        const hasPermission = await requestMicrophonePermission();
        console.log("Microphone permission:", hasPermission); // Add this
        if (!hasPermission) {
          toast.warning(
            "Microphone permission is required for speech recognition."
          );
          return;
        }

        console.log("Starting speech recognition...");
        recognitionRef.current?.start();
      } else {
        console.log("Stopping speech recognition...");
        recognitionRef.current?.stop();
      }
    } catch (error) {
      console.error("Error toggling microphone:", error);
      setIsListening(false);
      setMicActive(false);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "24px";
      const newHeight = Math.min(textarea.scrollHeight, 5 * 20);
      textarea.style.height = `${Math.max(24, newHeight)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const trimToWordLimit = (text, limit = 3000) => {
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    return words.length > limit ? words.slice(0, limit).join(" ") : text;
  };

  const handleInputChange = (e) => {
    const inputText = trimToWordLimit(e.target.value);
    if (inputText !== prompt) {
      setPrompt(inputText);
    }
  };

  const handleSendingPrompt = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (prompt.trim() !== "" && !promptDisabled) {
        setPromptDisable(true); // Disable input while sending
        if (isListening) {
          recognitionRef.current?.stop();
          setIsListening(false);
          setMicActive(false);
        }
        try {
          await send(); // Wait for send to finish
        } catch (error) {
          console.log("Error in sending prompt:", error);
        }
        setPromptDisable(false); // Re-enable input after sending
        setPrompt(""); // Clear prompt
        if (textareaRef.current) {
          textareaRef.current.style.height = "24px"; // Reset height
        }
      }
    }
  };

  return (
    <div className="chat-input-container max-w-5xl mx-auto my-2 w-full px-6 ">
      <div className="dark:bg-[#353C4D] bg-white rounded-2xl px-3 sm:px-4 py-2 sm:py-3">
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) =>
            handleFileUpload(
              e,
              chat_id,
              fileInputRef,
              setFiles,
              files,
              user,
              orgId
            )
          }
          accept=".pdf,.csv,.docx,.txt,.xlsx,.doc"
          id="chatFileInput"
        />
        <div className="relative my-2">
          <div className="relative">
            <textarea
              placeholder="Enter your prompt here..."
              className="w-full bg-transparent dark:text-white text-[#0D3148] placeholder-gray-400 placeholder:italic px-3 pr-12 focus:outline-none text-xs sm:text-sm resize-none overflow-y-auto leading-5"
              ref={textareaRef}
              value={prompt}
              disabled={promptDisabled} // This will now properly disable the input field
              onChange={handleInputChange}
              onKeyDown={(e) => handleSendingPrompt(e)}
              // disabled={promptDisabled} // Disable input when prompt is being processed
              aria-label="Prompt input"
              style={{ height: "24px", minHeight: "24px" }}
            />
            <div className="absolute right-0 top-[-8px] bottom-0 w-12 flex items-center justify-center my-2">
              <button
                onClick={
                  !promptDisabled
                    ? prompt && !isListening
                      ? send
                      : toggleMic
                    : null
                }
                className={`transition-colors ${
                  promptDisabled
                    ? "opacity-50 cursor-not-allowed"
                    : prompt
                    ? "text-amber-400 hover:text-amber-300"
                    : isListening
                    ? "text-red-400 animate-pulse"
                    : "text-emerald-400 hover:text-emerald-300"
                }`}
                aria-label={text ? "Send message" : "Voice input"}
              >
                {!isListening && prompt ? (
                  <div className="bg-amber-400 rounded-full p-2">
                    <IoArrowUp size={16} className="text-white" />
                  </div>
                ) : (
                  <>
                    {micActive ? (
                      theme === "dark" ? (
                        <MicActive size={20} />
                      ) : (
                        <BlackMicActive size={20} />
                      )
                    ) : theme === "dark" ? (
                      <Mic size={20} />
                    ) : (
                      <BlackMic size={20} />
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap items-center gap-2 border-t border-[#CCCCCC] dark:border-[#454E5A] pt-2">
          <div className="flex gap-2 flex-shrink-0">
            {/* Attach Dropdown */}
            <AttachDropdown fileInputRef={fileInputRef} />

            {/* Respond Style Dropdown */}
            <StyleDropdown
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
            />

            {/* globalSearch Icon */}
            <GlobalSearch
              expanded={expanded}
              setExpanded={setExpanded}
              disabled={files.length > 0}
            />

            <div
              className={`${
                files?.length
                  ? "border-r border-[#CCCCCC] dark:border-[#454E5A]"
                  : ""
              }`}
            ></div>
          </div>

          {/* Files display section */}
          <div className="flex-grow overflow-x-auto hide-scrollbar">
            <div
              className="flex gap-2 items-center"
              // style={{ width: "max-content" }}
            >
              {files?.length
                ? files.map((file, index) => (
                    <FileUploadItem
                      key={index}
                      file={file}
                      onRemove={removeFile}
                      progress={file?.progress || 0}
                      isUploading={uploadingFiles[file.name]}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
