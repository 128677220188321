import { create } from 'zustand';

const FileUploadStore = create((set) => ({
  fileSize: [],
  inputFileName: [],
  fileUrl: [],
  filePath: [],
  progress: 0,
  fileLoading: false,
  fileInputRef: null,
  showUpload: false,
  isUploading: false,
  isUploadVisible: false,
  uploadMainRef: null,
  setFilePath: (value) => set({ filePath: value }),
  setFileSize: (size) => set({ fileSize: size }),
  setProgress: (value) => set({ progress: value }),
  setFileLoading: (value) => set({ fileLoading: value }),
  setIsUploadVisible: (value) => set({ isUploadVisible: value }),
  setIsUploading: (value) => set({ isUploading: value }),
  setShowUpload: (value) => set({ showUpload: value }),
  setInputFileName: (value) => set({ inputFileName: value }),
  setFileUrl: (value) => set({ fileUrl: value }),
}));

export const FileStore = create(set => ({
  files: [],
  addFile: (file) => set(state => {
    return { files: [...state.files, file] }
  }),
  removeFile: (file) => set(state => ({ files: state.files.filter(f => f !== file) })),
  setFiles: (files) => set({ files: files?.length ? files : [] }),
  fileLoading: false,
  progress: 0,
  setFileLoading: (value) => set({ fileLoading: value }),
  setProgress: (value) => set({ progress: value }),
}))

export default FileUploadStore;
