import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import PromptStore from "../../store/promptStore";
import UIStore from "../../store/uiStore";
import ChatStore from "../../store/chatStore";
import UserAuthStore from "../../store/userStore";
import { logout } from "../../api/logout";
import EnvStore from "../../store/secretStore";
import FileUploadStore from "../../store/fileUploadStore";

const addChat = async ({
  RemoveUpload,
  getChat,
}) => {
  try {
    const { setPrompt } = PromptStore.getState();
    const { user, email, orgId } = UserAuthStore.getState();

    if (!user) {
      logout()
      return
    }

    const {
      setActiveChat,
      setAddChatDisabled,
      setSideChat,
      sideChat
    } = ChatStore.getState();
    const { setInputFileName, setFileSize, setFileUrl, setShowUpload } =
      FileUploadStore.getState();

    const { modeRef } = UIStore.getState();

    // if (!["org", "global"].includes(modeRef)) {
    //   return;
    // }

    setAddChatDisabled(true);
    const RemoveUpload = () => {
      setInputFileName([]);
      setFileSize([]);
      setFileUrl([]);
      setShowUpload(false);
    };

    RemoveUpload();

    const chatId = uuidv4();

    const data = {
      uid: chatId,
      user_id: user?.uid,
      email: email,
      title: "New Chat",
      file_url: [],
      file_name: [],
      file_size: [],
      file_path: [],
      mode: modeRef,
      timestamp: serverTimestamp(),
      updated_at: serverTimestamp(),
    };

    const userDocRef = doc(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      chatId
    );

    await setDoc(userDocRef, data);
    setActiveChat(chatId);
    setSideChat([...sideChat, chatId]);
    setPrompt("");
    getChat(modeRef, email);
    return chatId;
  } catch (error) {
    console.error("Error adding chat in Firestore:", error);
  }
};

export default addChat;
