import React, { useState, useEffect, useRef } from "react";
import { getModelList } from "../../api/getModels";
import { FaArrowsRotate, FaCheck } from "react-icons/fa6";
import { WebIconDark, WebIconLight } from "../../assets";
import { useTheme } from "../../context/ThemeProvider";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";

const RegenerateDropdown = ({ chatId, onRegenerate, showLoadingAnswer, files }) => {
  const { theme } = useTheme();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [dropDirection, setDropDirection] = useState("top");
  const [selectedModel, setSelectedModel] = useState(null);
  const [hoveredModel, setHoveredModel] = useState(null);
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isWebSearchChecked, setIsWebSearchChecked] = useState(false); // New state for checkbox

  useEffect(() => {
    const fetchModels = async () => {
      setIsLoading(true);
      try {
        const response = await getModelList();
        if (response?.models) {
          const modelsArray = Object.values(response.models).map(
            ({ id, name, active }) => ({
              id,
              name,
              active: active === "True" || active === true || active === "true",
            })
          );

          const activeModels = modelsArray.filter((model) => model.active);
          setModels(activeModels);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
        setModels([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModels();
  }, []);

  const handleModelSelect = async (model) => {
    if(model.id === "gemini-2.0-flash-001" && isWebSearchChecked) {
      toast.error("Google Search is not available for this model");
      setIsOpen(false);
      return;
    }
    else {
      setSelectedModel(model.id);
      setIsOpen(false);
      await onRegenerate(chatId, model.id, isWebSearchChecked); // Pass checkbox state
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // useEffect(() => {
  //   if (isOpen && buttonRef.current) {
  //     const buttonRect = buttonRef.current.getBoundingClientRect();
  //     const spaceAbove = buttonRect.top;
  //     const spaceBelow = window.innerHeight - buttonRect.bottom;

  //     setDropDirection(
  //       spaceAbove < 300 && spaceBelow > spaceAbove ? "bottom" : "top"
  //     );
  //   }
  // }, [isOpen]);

  // 🔥 Updated positioning logic using useLayoutEffect for better sync
  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      if (buttonRect.top > 350) {
        dropdownRef.current.style.top = `${buttonRect.bottom - dropdownRef.current.offsetHeight - 28 - 2}px`;
      } else {
        dropdownRef.current.style.top = `${buttonRect.bottom + 2}px`;
      }
      dropdownRef.current.style.left = `${buttonRect.right - 250}px`;
    }
  }, [isOpen, buttonRef]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (isOpen) {
  //       setIsOpen(false); // Close dropdown on scroll
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll, true);
  //   return () => window.removeEventListener("scroll", handleScroll, true);
  // }, [isOpen]);

  useEffect(() => {
    if (files.length > 0) {
      setIsWebSearchChecked(false);
    }
  }, [files]);

  return (
    <div className="relative" style={{ zIndex: 10 }}>
      <button
        ref={buttonRef}
        onClick={() => {
          if (!showLoadingAnswer) {
            setIsOpen((prev) => !prev);
          }
        }}
        className={`flex items-center gap-2 text-sm rounded-lg p-1.5 dark:bg-[#444858] bg-[#FFE9E6]
          ${
            showLoadingAnswer
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer hover:bg-gray-700/30"
          }
          transition-all duration-200`}
      >
        <FaArrowsRotate className="w-3 h-3 dark:fill-emerald-400 fill-[#FF5B29]" />
        <span className="dark:text-emerald-400 text-[#FF5B29] text-xs">Regenerate</span>
      </button>

      {isOpen && createPortal (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            // top: `${dropdownPosition.top}px`,
            // left: `${dropdownPosition.left}px`,
            width: "250px",
            zIndex: 50,
          }}
          className="rounded-lg dark:bg-[#424453] bg-white border border-gray-700"
        >
          <div className="px-4 py-3 text-sm dark:text-[#9FABCA] text-gray-400">
            Switch model
          </div>

          <div className="min-h-52 max-h-52 overflow-y-auto">
            {isLoading ? (
              <div className="px-4 py-2 text-gray-400 text-sm text-center">
                Loading models...
              </div>
            ) : models.length > 0 ? (
              models.map((model) => (
                <div
                  key={model.id}
                  onClick={() => handleModelSelect(model)}
                  onMouseEnter={() => setHoveredModel(model.id)}
                  onMouseLeave={() => setHoveredModel(null)}
                  className="px-4 py-2.5 flex items-center justify-between dark:text-white text-gray-600 hover:bg-gray-500/30 cursor-pointer group"
                >
                  <span className="text-sm">{model.name}</span>
                  {selectedModel === model.id ? (
                    hoveredModel === model.id ? (
                      <button
                        className="dark:text-emerald-400 text-[#FF5B29] text-xs px-1 py-1 rounded flex items-center gap-1 hover:bg-emerald-400/10"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModelSelect(model);
                        }}
                      >
                        Try Again
                        <FaArrowsRotate className="w-3 h-3" />
                      </button>
                    ) : (
                      <FaCheck className="w-4 h-4 dark:text-emerald-400 text-[#FF5B29]" />
                    )
                  ) : null}
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-gray-400 text-sm text-center">
                No models available
              </div>
            )}
          </div>

          <div className="px-4 py-2 border-t border-gray-700 flex items-center justify-between gap-2 dark:text-white">
            <div className="flex items-center gap-3">
              <input
                type="checkbox"
                id="webSearch"
                checked={isWebSearchChecked}
                onChange={(e) => setIsWebSearchChecked(e.target.checked)}
                disabled={files.length > 0}
                className="dark:accent-emerald-400 accent-[#FF5B29] w-4 h-4"
              />
              <label htmlFor="webSearch" className="text-sm cursor-pointer">
                Search the web
              </label>
            </div>
            {theme === "dark" ? (
              <WebIconDark className="h-4 w-4 fill-[#37E8C5]" />
            ) : (
              <WebIconLight className="h-4 w-4 fill-[#FF5B29]" />
            )}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default RegenerateDropdown;
