import axios from "axios";
import { apiUrl } from "../constants/const";

export const getDefaultModel = async () => {
  try {
    const response = await axios.get(`${apiUrl}/get_default_model`);
    return response.data; // Ensure response is returned
  } catch (err) {
    console.error("Error fetching models:", err);
    return { models: "" }; // Return an empty string on failure to prevent errors
  }
};
