import { collection, query, where, orderBy, getDocs, getDoc, doc } from "firebase/firestore";
import EnvStore from "../../store/secretStore";
import UserAuthStore from "../../store/userStore";
import { FileStore } from "../../store/fileUploadStore";

const getMessages = async (chatId) => {
  try {
    const db = EnvStore.getState().db;
    const { user, orgId } = UserAuthStore.getState()
    const { setFiles } = FileStore.getState();

    // Reference to the messages collection
    const messagesRef = collection(
      db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      chatId,
      "messages"
    );

    // Query to fetch user prompts sorted by timestamp
    const promptsQuery = query(
      messagesRef,
      where("role", "==", "user"),
      orderBy("timestamp")
    );

    const promptsSnapshot = await getDocs(promptsQuery);

    const results = [];
    const answerIds = new Set();

    // Collect all answer IDs while iterating over prompts
    promptsSnapshot.forEach((promptDoc) => {
      const promptData = promptDoc.data();
      promptData.id = promptDoc.id; // Add document ID for reference

      if (promptData.source === "None") {
        promptData.source = []
      } else {
        promptData.source = promptDoc.source
      }

      if (promptData.answers) {
        promptData.answers.forEach((id) => answerIds.add(id));
      }

      if (promptData.source === "None") {
        promptData.source = []
      } else {
        promptData.source = promptDoc.source
      }

      results.push(promptData);
    });

    // Fetch all answers in a single batch
    const answersMap = {};
    if (answerIds.size > 0) {
      const answerDocs = await Promise.all(
        Array.from(answerIds).map((id) =>
          getDoc(doc(messagesRef, id)) // Fetch each answer document
        )
      );

      answerDocs.forEach((docSnapshot) => {
        if (docSnapshot.exists()) {
          answersMap[docSnapshot.id] = docSnapshot.data();
        }
      });
    }

    // Attach answers to corresponding prompts
    results.forEach((result) => {
      if (result.answers) {
        result.answers = result.answers.map((id) => answersMap[id]).filter(Boolean);
      }
    });
    const chatDocRef = doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid, "chats", chatId);
    const chatDoc = await getDoc(chatDocRef);
    // console.log(chatDoc.data())
    setFiles(chatDoc.data()?.file_name.map((name, index) => ({
      name: name,
      size: chatDoc.data()?.file_size[index],
      downloadURL: chatDoc.data()?.file_url[index],
      file__path: chatDoc.data()?.file_path[index],
    })));
    return results;
  } catch (error) {
    console.error("Error fetching messages from Firestore:", error);
    throw error;
  }
};

export default getMessages;
